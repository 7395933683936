/*@import "base"; */



/* CSS styles for the personal website */


body {
    //line-height: 1.6;
    //color: #333;
    background-color: #f4f4f9;
    //margin: 20px;
    
    font-family: "DM Sans", sans-serif;
    //font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;

    background-image: url('/assets/julien-drawing.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 500px auto;
    background-attachment: fixed;
}

@media (max-width: 1050px) { /* Adjust width as needed to match your mobile breakpoints */
    body {
        margin-bottom: 500px;
        background-attachment: scroll;
    }
}

h1 {
    font-family: "Libre Baskerville", serif;
    color: #004de5;
    text-align: center;
}

h2, h3, h4, h5, h6 {
    //font-family: "JetBrains Mono", monospace;
    font-family: "DM Sans", sans-serif;
    color: #004ce5;
}

a, a:visited, a:active {
    font-family: "JetBrains Mono", monospace;
    color: rgb(11, 88, 255);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

em {
    font-family: "JetBrains Mono", monospace;
    font-style: normal;
}

main {
    margin: 40px;
    max-width: 580px;
}

@media (min-width: 800px) { /* Adjust width as needed to match your mobile breakpoints */
    main {
        margin: 100px;
        max-width: 580px;
    }
}
